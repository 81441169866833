import React from 'react';
import { Label, Link, mergeStyleSets, Text } from '@fluentui/react';
import {
    GetMeasuresForStatusReportQuery,
    ReportPeriodTypes,
    useGetMeasurePeriodDataQuery,
    ValueTypes,
} from '../../../data/types';
import {
    ExtractQueryArrayType,
    MeasureSeriesNames,
} from '../../../data/extendedTypes';
import MeasureArrow from '../../../components/MeasureArrow';
import dayjs from 'dayjs';
import { useStateContext } from '../../../services/contextProvider';
import { getLabelForDate } from '../../Measure/utils/measureUtils';
import { useThemes } from '../../../hooks/useThemes';
import { MeasureStatusColours } from '../../../Colours';
import { useFormatters } from '../../../hooks/useFormatters';

export function StatusReportGridMeasureDetail(props: {
    measure: ExtractQueryArrayType<
        GetMeasuresForStatusReportQuery,
        ['measures']
    > | null;
    fyStartDate: string;
    allowNavigation: boolean;
    onNavigate: () => void;
    hideLabel?: boolean;
    forDateTime: string | null;
}): JSX.Element {
    const { currentTenantId } = useStateContext();
    const { currentTheme } = useThemes();
    const formatters = useFormatters();

    const { measure } = props;

    const isHistoric = !!props.forDateTime;
    const lastAsOf = isHistoric ? measure?.asOfForDate : measure?.lastAsOf;

    const { data: ytdData } = useGetMeasurePeriodDataQuery({
        skip:
            !measure?.id ||
            !currentTenantId ||
            measure.valueType !== ValueTypes.Incremental,
        variables: {
            forDateTime: props.forDateTime,
            measureId: measure?.id || '',
            tenantId: currentTenantId || '',
            reportPeriod: null,
            reportPeriodType: ReportPeriodTypes.Ytd,
        },
    });

    const targetFormatted = lastAsOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.Target
    )?.formatStr;

    const actualFormatted = lastAsOf?.values?.find(
        (v) => v.seriesType?.name === MeasureSeriesNames.Actual
    )?.formatStr;

    const ytdActualFormatted =
        ytdData?.periodData?.measurePeriodData?.actualFormatted;

    const ytdTargetFormatted =
        ytdData?.periodData?.measurePeriodData?.targetFormatted;

    const ytdStatusValue = ytdData?.periodData?.measurePeriodData?.statusValue;

    const formattedStatus =
        ytdActualFormatted && ytdTargetFormatted
            ? formatters.formatMeasureStatus(
                  ytdStatusValue,
                  props.measure?.isStatusLimited || false
              )
            : null;

    let statusColour = null;
    if (lastAsOf?.arrowColour === 'GREEN') {
        statusColour = MeasureStatusColours.onTarget;
    } else if (lastAsOf?.arrowColour === 'RED') {
        statusColour = MeasureStatusColours.offTarget;
    } else if (lastAsOf?.arrowColour === 'YELLOW') {
        statusColour = MeasureStatusColours.nearTarget;
    } else {
        statusColour = currentTheme.palette.neutralQuaternary;
    }

    const asOfLabel =
        measure?.frequencyPeriod && lastAsOf?.asOfDate
            ? getLabelForDate(
                  props.fyStartDate,
                  measure?.frequencyPeriod,
                  lastAsOf?.asOfDate,
                  false
              )
            : null;

    const classNames = mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        values: {
            borderLeftWidth: 4,
            borderLeftColor: currentTheme.palette.neutralLight,
            borderLeftStyle: 'solid',
            paddingLeft: 8,
            display: 'flex',
            flexDirection: 'row',
            gap: 4,
        },
        thisPeriod: {
            flexGrow: 1,
            flexBasis: 0,

            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        },
        ytd: {
            flexGrow: 1,
            flexBasis: 0,

            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderLeftWidth: 2,
            borderLeftColor: currentTheme.palette.neutralLight,
            borderLeftStyle: 'solid',
            paddingLeft: 8,
        },
        statusBox: {
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
        },
    });

    return (
        <div className={classNames.container}>
            <div>
                {!props.hideLabel && (
                    <Label>
                        Measure of Success{' '}
                        {!measure || measure.utcDeleted
                            ? `(Deleted ${dayjs(measure?.utcDeleted).format(
                                  'DD MMM YYYY'
                              )})`
                            : ''}
                    </Label>
                )}
                <Text
                    variant="mediumPlus"
                    block
                    styles={{
                        root: {
                            textDecoration: measure?.utcDeleted
                                ? 'line-through'
                                : undefined,
                        },
                    }}
                >
                    {props.allowNavigation && !measure?.utcDeleted ? (
                        <Link onClick={props.onNavigate}>{measure?.name}</Link>
                    ) : (
                        measure?.name
                    )}
                </Text>
            </div>

            <div
                className={classNames.values}
                style={{
                    borderLeftColor: statusColour,
                    textDecoration: measure?.utcDeleted
                        ? 'line-through'
                        : undefined,
                }}
            >
                <div className={classNames.thisPeriod}>
                    {!!asOfLabel && (
                        <Text variant="smallPlus">{asOfLabel}</Text>
                    )}

                    {!!actualFormatted && (
                        <Text variant="large" style={{ whiteSpace: 'nowrap' }}>
                            {actualFormatted}
                        </Text>
                    )}

                    {!!targetFormatted && (
                        <Text variant="small" style={{ whiteSpace: 'nowrap' }}>
                            Target: {targetFormatted}
                        </Text>
                    )}

                    {!!targetFormatted && !!actualFormatted && (
                        <div className={classNames.statusBox}>
                            <Text variant="small">Status:</Text>
                            <MeasureArrow
                                isStatusLimited={
                                    measure?.isStatusLimited || false
                                }
                                arrowColour={lastAsOf?.arrowColour}
                                arrowDirection={
                                    lastAsOf?.arrowDirection || 'SAME'
                                }
                                values={lastAsOf?.values}
                                iconFontSize={12}
                                textFontSize={10}
                                showPercentage
                                percentagePosition="Left"
                                statusValue={lastAsOf?.statusValue || 0}
                            />
                        </div>
                    )}
                </div>

                {(!!ytdActualFormatted || !!ytdTargetFormatted) && (
                    <div className={classNames.ytd}>
                        <Text variant="smallPlus">YTD</Text>
                        {!!ytdActualFormatted && (
                            <Text
                                variant="large"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                {ytdActualFormatted}
                            </Text>
                        )}
                        {!!ytdTargetFormatted && (
                            <Text
                                variant="small"
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                Target: {ytdTargetFormatted}
                            </Text>
                        )}
                        {!!ytdActualFormatted && !!ytdTargetFormatted && (
                            <div className={classNames.statusBox}>
                                <Text variant="small">
                                    Status: {formattedStatus}
                                </Text>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
