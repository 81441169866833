import { Label } from '@fluentui/react';
import styles from './ApiHelp.module.css';
import { useStateContext } from '../../../../services/contextProvider';

export function ApiHelpDivisions(): JSX.Element {
    return (
        <div>
            <h1>Divisions</h1>
            A company may have one or more Divisions. Each Division contains
            Teams.
            <br />
            <p>
                <Label>OData Query Parameters</Label>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                <code className={styles.method}>GET</code>
                                <code className={styles.code}>/Division</code>
                            </td>
                            <td>
                                Get a List of Divisions
                                <br />
                                <br />
                                Expandable properties: <br />
                                <code className={styles.code}>
                                    ?$expand=FinancialYear
                                </code>
                                <br />
                                <code className={styles.code}>
                                    ?$expand=Teams
                                </code>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <code className={styles.method}>GET</code>
                                <code className={styles.code}>
                                    /Division(&#123;id&#125;)
                                </code>
                            </td>
                            <td>
                                Get single Division
                                <p>
                                    <i>
                                        (Replace &#123;id&#125; with the guid
                                        identifying the individual record).
                                    </i>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </p>
        </div>
    );
}
