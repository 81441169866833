import React, { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
    ActionButton,
    IButtonProps,
    Link,
    mergeStyleSets,
    Spinner,
    SpinnerSize,
    Text,
    useTheme,
} from '@fluentui/react';
import { useActiveView } from '../../hooks/useActiveView';
import {
    AreaContainer,
    AreaContainerButton,
} from '../MissionBuilder/components/AreaContainer';
import Loading from '../../components/Loading';
import { TemplateReportElement } from './components/TemplateReportElement';
import { useViewport } from '../../hooks/useViewport';
import {
    GetTemplateReportQuery,
    ReportPeriodTypes,
    TemplateReportElementTypes,
    TemplateReportInput,
    useDeleteTemplateReportMutation,
    useGetTemplateReportQuery,
    useGetTemplateReportSectionLazyQuery,
    useUpdateTemplateReportElementMutation,
    useUpdateTemplateReportMutation,
    useUpdateTemplateReportSectionMutation,
} from '../../data/types';
import { useStateContext } from '../../services/contextProvider';
import { sorters } from '../../data/sorters';
import { StatusReportTitle } from '../StatusReports/StatusReportTitle';
import { ErrorMessageBar } from '../../components/shared/ErrorMessageBar';
import { TemplateReportAddPanel } from './components/TemplateReportAddPanel';
import {
    ExtractQueryArrayType,
    ExtractQueryType,
} from '../../data/extendedTypes';
import DeleteModal from '../../components/shared/DeleteModal';
import { StatusReportPublishDialog } from '../StatusReports/StatusReportPublishDialog';
import { StatusReportUnPublishDialog } from '../StatusReports/StatusReport';
import { paths } from '../../services/navigation';
import dayjs from 'dayjs';
import { StatusReportDataChangedIndicator } from '../StatusReports/components/StatusReportDataChangedIndicator';
import { EditPanels, PanelTypes } from '../../components/EditPanels';
import { templateReportExport } from '../../services/exporter';
import { TemplateReportEditLayoutPanel } from './components/TemplateReportEditLayoutPanel';
import { EditibleText } from '../../components/EditibleText';
import { TemplateReportChangePeriodDialog } from './TemplateReportChangePeriodDialog';
import { useTemplateReportPeriods } from './hooks/useTemplateReportPeriods';

export default function TemplateReport(): JSX.Element {
    const params = useParams();
    const { missionId, templateReportId } = params;

    const { currentTenantId, configuration, currentRoles } = useStateContext();

    const isAdmin = currentRoles.some((r) =>
        ['GlobalAdmin', 'ClientAdmin', 'Developer'].includes(r)
    );

    const currentTheme = useTheme();
    const navigate = useNavigate();

    const [docTitle, setDocTitle] = useState<string | undefined>();

    const [activePanel, setActivePanel] = useState<PanelTypes>(null);
    const [activeMeasureId, setActiveMeasureId] = useState<string | null>();
    const [activeTaskId, setActiveTaskId] = useState<string | null>();
    const [selectedTemplateViewId, setSelectedTemplateViewId] =
        useState<string>();

    const [elementUpdateError, setElementUpdateError] = useState<
        Error | undefined | null
    >();

    const [exportError, setExportError] = useState<Error | undefined | null>();

    const handleElementUpdateError = (error: Error | undefined | null) => {
        setElementUpdateError(error);
    };

    const { data, loading } = useGetTemplateReportQuery({
        skip: !currentTenantId || !templateReportId,
        variables: {
            tenantId: currentTenantId || '',
            id: templateReportId || '',
        },
    });

    const [loadTemplateReportSection] = useGetTemplateReportSectionLazyQuery({
        fetchPolicy: 'network-only',
    });

    const [updateTemplateReport, { loading: isUpdating, error: updateError }] =
        useUpdateTemplateReportMutation();

    const [
        updateTemplateReportSection,
        { loading: isUpdatingSection, error: updateSectionError },
    ] = useUpdateTemplateReportSectionMutation();

    const [updateElementMutation] = useUpdateTemplateReportElementMutation({
        onError: handleElementUpdateError,
    });

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const handleDeleteButtonClick = () => setIsDeleteModalOpen(true);

    const [isExporting, setIsExporting] = useState(false);

    const report = data?.reports?.templateReport;
    const mission = report?.mission;

    const fyStartDate = mission?.team?.division?.financialYear?.startDate;
    const fyEndDate = mission?.team?.division?.financialYear?.endDate;

    const leaderMission = mission?.team?.leaderMission;
    const teamLeader =
        leaderMission?.userId &&
        leaderMission?.username &&
        leaderMission?.userId !== mission?.userId
            ? {
                  userId: leaderMission?.userId,
                  displayName: leaderMission?.username,
              }
            : null;

    const hasError =
        !!updateError || !!updateSectionError || !!elementUpdateError;

    const errorMessageBarContainer = useRef<HTMLDivElement>(null);

    const { formatPeriod } = useTemplateReportPeriods(fyStartDate, fyEndDate);

    useEffect(() => {
        if (hasError) {
            errorMessageBarContainer.current?.scrollIntoView();
        }
    }, [hasError]);

    const isReadOnly =
        !mission?.rights.write ||
        report?.utcCompletedDate !== null ||
        hasError ||
        !!selectedTemplateViewId;

    useActiveView('TemplateReport', docTitle);

    useEffect(() => {
        setDocTitle(report?.title || '');
    }, [report?.title]);

    const [addPanelElement, setAddPanelElement] = useState<{
        sectionId: string;
        elementType: TemplateReportElementTypes;
    } | null>();

    const [editSectionLayoutPanel, setEditSectionLayoutPanel] = useState<{
        sectionId: string;
        layoutDirection: string | null;
    } | null>();

    const closeAddElementPanel = () => setAddPanelElement(null);
    const closeEditSectionLayoutPanel = () => setEditSectionLayoutPanel(null);

    const showAddElementPanel = (
        sectionId: string,
        elementType: TemplateReportElementTypes
    ) => {
        setAddPanelElement({
            sectionId: sectionId,
            elementType: elementType,
        });
    };

    const showSectionLayoutPanel = (
        sectionId: string,
        layoutDirection: string
    ) => {
        setEditSectionLayoutPanel({
            sectionId: sectionId,
            layoutDirection: layoutDirection,
        });
    };

    const handleElementsAdded = async (
        elements: TemplateReportElementType[],
        group?: TemplateReportGroupType | null
    ) => {
        if (report && currentTenantId && addPanelElement?.sectionId) {
            const section = report?.sections.find(
                (s) => s.id === addPanelElement?.sectionId
            );

            let newGroupId: string | null = null;

            if (group) {
                if (!section) {
                    throw 'Section not found';
                }

                const res = await updateTemplateReportSection({
                    variables: {
                        tenantId: currentTenantId,
                        input: {
                            id: addPanelElement.sectionId,
                            layoutDirection: 'column',
                            availableElementTypes:
                                section.availableElementTypes,
                            title: section.title,
                            sequence: section.sequence,
                            groups: [
                                ...section.groups.map((g) => ({
                                    id: g.id,
                                    sequence: g.sequence,
                                    layoutGrow: g.layoutGrow,
                                    layoutDirection: g.layoutDirection,
                                    version: g.version,
                                })),
                                {
                                    id: null,
                                    layoutGrow: group.layoutGrow,
                                    layoutDirection: group.layoutDirection,
                                    sequence: group.sequence,
                                    version: '',
                                },
                            ],
                            version: section.version,
                        },
                    },
                });

                const groups = res.data?.templateReportSectionUpdate.groups;

                const newGroup = groups?.length
                    ? groups.slice().sort(sorters.sequenceSorter)[
                          groups.length - 1
                      ]
                    : null;

                newGroupId = newGroup?.id || null;
            }

            await Promise.all(
                elements.map((element) => {
                    return updateElementMutation({
                        variables: {
                            tenantId: currentTenantId,
                            input: {
                                id: null,
                                sectionId: addPanelElement.sectionId,
                                attachmentId: null,
                                textContent: element.textContent,
                                groupId: newGroupId || element.groupId,
                                layoutGrow: element.layoutGrow,
                                sequence: element.sequence,
                                title: element.title,
                                type: element.type,
                                measureLinks: element.measureLinks,
                                taskLinks: element.taskLinks,
                                columns: element.columns,
                                sourceTemplateReportSectionElementId:
                                    element.sourceTemplateReportSectionElementId,
                                version: '',
                            },
                        },
                    });
                })
            );

            await loadTemplateReportSection({
                variables: {
                    tenantId: currentTenantId,
                    id: addPanelElement.sectionId,
                },
            });
        }
    };

    const handleLayoutChanged = async (layoutDirecttion: string) => {
        if (editSectionLayoutPanel?.sectionId && currentTenantId) {
            const section = report?.sections.find(
                (s) => s.id === editSectionLayoutPanel?.sectionId
            );

            if (section) {
                await updateTemplateReportSection({
                    variables: {
                        tenantId: currentTenantId,
                        input: {
                            id: editSectionLayoutPanel.sectionId,
                            layoutDirection: layoutDirecttion,
                            availableElementTypes:
                                section.availableElementTypes,
                            title: section.title,
                            sequence: section.sequence,
                            groups: section.groups.map((g) => ({
                                id: g.id,
                                sequence: g.sequence,
                                layoutGrow: g.layoutGrow,
                                layoutDirection: g.layoutDirection,
                                version: g.version,
                            })),
                            version: section.version,
                        },
                    },
                });
            }
        }
    };

    const handleSectionTitleChanged = async (
        sectionId: string | null | undefined,
        newTitle: string | null | undefined
    ) => {
        if (sectionId && currentTenantId) {
            const section = report?.sections.find((s) => s.id === sectionId);

            if (section) {
                await updateTemplateReportSection({
                    variables: {
                        tenantId: currentTenantId,
                        input: {
                            id: sectionId,
                            layoutDirection: section.layoutDirection,
                            availableElementTypes:
                                section.availableElementTypes,
                            title: newTitle || '',
                            sequence: section.sequence,
                            groups: section.groups.map((g) => ({
                                id: g.id,
                                sequence: g.sequence,
                                layoutGrow: g.layoutGrow,
                                layoutDirection: g.layoutDirection,
                                version: g.version,
                            })),
                            version: section.version,
                        },
                    },
                });
            }
        }
    };

    const [hideSubmitDialog, setHideSubmitDialog] = useState(true);
    const [hideUnsubmitDialog, setHideUnsubmitDialog] = useState(true);
    const [hidePeriodChangeDialog, setHidePeriodChangeDialog] = useState(true);

    const handleSubmitButtonClick = () => setHideSubmitDialog(false);
    const handleSubmitDialogDismiss = () => setHideSubmitDialog(true);
    const handleUnsubmitButtonClick = () => setHideUnsubmitDialog(false);
    const handleUnsubmitDialogDismiss = () => setHideUnsubmitDialog(true);

    const handleConfirmSubmitClick = async (
        title: string,
        reportDate: string | null,
        notifyUserIds: string[]
    ) => {
        if (report && currentTenantId) {
            await updateTemplateReport({
                variables: {
                    tenantId: currentTenantId,
                    input: {
                        ...mapToInput(report),
                        reportDate: reportDate,
                        title: title,
                        utcCompletedDate: dayjs.utc().format(),
                    },
                    notifyUserIds: notifyUserIds,
                },
            });
        }
        setHideSubmitDialog(true);
    };

    const handleConfirmUnsubmitClick = async (getLatestValues: boolean) => {
        if (report && currentTenantId) {
            await updateTemplateReport({
                variables: {
                    tenantId: currentTenantId,
                    input: {
                        ...mapToInput(report),
                        utcCompletedDate: null,
                        utcDataDate: getLatestValues
                            ? null
                            : (report.utcDataDate ?? report.utcCompletedDate),
                    },
                    notifyUserIds: [],
                },
            });
        }
        setHideUnsubmitDialog(true);
    };

    const handlePeriodSaveClick = async (
        reportPeriodType: ReportPeriodTypes,
        reportPeriod: number
    ) => {
        if (report && currentTenantId) {
            await updateTemplateReport({
                variables: {
                    tenantId: currentTenantId,
                    input: {
                        ...mapToInput(report),
                        reportPeriodType: reportPeriodType,
                        reportPeriod: reportPeriod,
                    },
                    notifyUserIds: [],
                },
            });
        }
        setHidePeriodChangeDialog(true);
    };

    const handleRefreshDataButtonClick = async () => {
        if (report && currentTenantId) {
            await updateTemplateReport({
                variables: {
                    tenantId: currentTenantId,
                    input: {
                        ...mapToInput(report),

                        utcDataDate: null,
                    },
                    notifyUserIds: [],
                },
            });
        }
        setHideUnsubmitDialog(true);
    };

    const mapToInput = (
        report: ExtractQueryType<
            GetTemplateReportQuery,
            ['reports', 'templateReport']
        >
    ): TemplateReportInput => {
        const mapped: TemplateReportInput = {
            id: report.id,
            missionId: report.mission?.id || null,
            reportDate: report.reportDate,
            reportPeriod: report.reportPeriod,
            reportPeriodType: report.reportPeriodType,
            title: report.title,
            utcCompletedDate: report.utcCompletedDate,
            utcDataDate: report.utcDataDate,
            version: report.version,
        };
        return mapped;
    };

    const handleTitleUpdated = async (title?: string | null) => {
        if (report && currentTenantId) {
            updateTemplateReport({
                variables: {
                    tenantId: currentTenantId,
                    input: {
                        ...mapToInput(report),
                        title: title || '',
                    },
                    notifyUserIds: [],
                },
            });
        }
    };

    const handleMeasureNavigate = useCallback(
        (measureId: string) => {
            setActiveTaskId(null);
            setActiveMeasureId(measureId);
            setActivePanel(
                isReadOnly ? 'measure-overview' : 'edit-measure-actual'
            );
        },
        [isReadOnly]
    );

    const handleTaskNavigate = useCallback(
        (taskId: string) => {
            setActiveMeasureId(null);
            setActiveTaskId(taskId);
            setActivePanel(isReadOnly ? 'task-alignment' : 'edit-task');
        },
        [isReadOnly]
    );

    const onActiveTaskChanged = useCallback(
        (taskId: string | null) => setActiveTaskId(taskId),
        []
    );

    const handleActivePanelChanged = useCallback(
        (panelType: PanelTypes): void => setActivePanel(panelType),
        []
    );

    const handlePresentButtonClick = useCallback(() => {
        const href = selectedTemplateViewId
            ? generatePath(paths.presentTemplateReportView, {
                  ...params,
                  templateReportViewId: selectedTemplateViewId,
                  presentation: 'template-report',
              })
            : generatePath(paths.presentTemplateReport, {
                  ...params,
                  presentation: 'template-report',
              });
        navigate(href);
    }, [navigate, params, selectedTemplateViewId]);

    const handleExportButtonClick = async () => {
        try {
            if (report?.id) {
                setIsExporting(true);
                setExportError(null);
                await templateReportExport(
                    configuration,
                    report.id,
                    currentTenantId,
                    `${report?.mission?.owner} ${report.title || 'Report'}`,
                    selectedTemplateViewId
                );
            }
        } catch (ex: unknown) {
            const error = (ex as Error)
                ? new Error(
                      `The export failed, please try again later. ${
                          (ex as Error)?.message
                      }`
                  )
                : new Error(`The export failed, please try again later.`);
            setExportError(error);
            throw error;
        } finally {
            setIsExporting(false);
        }
    };

    if (!missionId || !report || loading) {
        return <Loading />;
    }

    const buttons: AreaContainerButton[] = [];

    if (report.views.length) {
        const viewButton: AreaContainerButton = {
            key: 'View',
            text: !selectedTemplateViewId
                ? 'Default View'
                : report.views.find((v) => v.id === selectedTemplateViewId)
                      ?.title || '',
            iconProps: {
                iconName: 'ThumbnailView',
            },
            subMenuProps: {
                items: [
                    {
                        key: 'default',
                        iconProps: { iconName: 'PreviewLink' },
                        text: 'Default View',
                        checked: !selectedTemplateViewId,
                        canCheck: true,
                        onClick: () => setSelectedTemplateViewId(undefined),
                    },
                ],
            },
        };

        report.views.forEach((v) => {
            viewButton.subMenuProps?.items.push({
                key: v.id || '',
                iconProps: { iconName: 'PreviewLink' },
                text: v.title || v.id || '',
                canCheck: true,
                checked: selectedTemplateViewId === v.id,
                onClick: () => {
                    if (v.id) {
                        setSelectedTemplateViewId(v.id);
                    }
                },
            });
        });

        buttons.push(viewButton);
    }

    if (report?.mission?.rights?.export) {
        buttons.push({
            key: 'Export',
            text: 'Export',
            iconProps: {
                iconName: 'PowerPointDocument',
            },
            disabled: isExporting,
            onClick: () => {
                handleExportButtonClick();
            },
            onRenderIcon: (_props, defaultRenderer) => {
                if (isExporting) {
                    return <Spinner size={SpinnerSize.small} />;
                }

                return defaultRenderer ? defaultRenderer() : null;
            },
        });
    }

    buttons.push({
        key: 'Present',
        text: 'Present',
        iconProps: {
            iconName: 'Presentation',
        },
        onClick: handlePresentButtonClick,
    });

    if (!report?.utcCompletedDate && mission?.rights.write) {
        buttons.push({
            key: 'PublishReport',
            text: 'Publish',
            iconProps: {
                iconName: 'PublishContent',
            },
            onClick: () => {
                handleSubmitButtonClick();
            },
        });
    }

    if (report?.utcCompletedDate && mission?.rights.write) {
        const disabled =
            !report.mission?.team?.division?.canUnpublishReport && !isAdmin;

        buttons.push({
            key: 'UnpublishReport',
            text: 'Unpublish',
            iconProps: {
                iconName: 'UnpublishContent',
            },
            disabled: disabled,
            title: disabled
                ? 'Only an admin can unpublish this report.'
                : undefined,
            onClick: handleUnsubmitButtonClick,
        });
    }

    if (mission?.rights.write && !report?.utcCompletedDate) {
        buttons.push({
            key: 'Delete',
            text: 'Delete',
            iconOnly: true,
            iconProps: {
                iconName: 'Delete',
            },
            onClick: () => {
                handleDeleteButtonClick();
            },
        });
    }

    const allUpdatedDates = [
        report?.utcUpdated,
        ...report.sections.map((s) => s.utcUpdated),
        ...report.sections.flatMap((s) => s.elements.map((e) => e.utcUpdated)),
    ];

    const lastSavedDate = report
        ? dayjs.max(allUpdatedDates.filter((d) => d).map((d) => dayjs.utc(d)))
        : null;

    const reportPeriodText = formatPeriod(
        report.reportPeriodType,
        report.reportPeriod
    );

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingTop: 8,
                    paddingRight: 16,
                    paddingLeft: 16,
                }}
            >
                <div style={{ alignSelf: 'end' }}>
                    {(!!updateError ||
                        !!updateSectionError ||
                        !!elementUpdateError ||
                        !!exportError) && (
                        <div ref={errorMessageBarContainer}>
                            <ErrorMessageBar
                                error={
                                    updateError ||
                                    elementUpdateError ||
                                    exportError
                                }
                            />
                        </div>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 32,
                        }}
                    >
                        {!!report?.reportPeriod &&
                            !!report?.reportPeriodType &&
                            report?.reportPeriodType !==
                                ReportPeriodTypes.None && (
                                <div>
                                    <Text
                                        variant="smallPlus"
                                        style={{ fontWeight: 600 }}
                                    >
                                        Reporting Period:{' '}
                                    </Text>
                                    {!isReadOnly ? (
                                        <Link
                                            onClick={() =>
                                                setHidePeriodChangeDialog(false)
                                            }
                                        >
                                            {reportPeriodText}
                                        </Link>
                                    ) : (
                                        <Text>{reportPeriodText}</Text>
                                    )}
                                </div>
                            )}

                        {!!report?.reportDate && !!report.utcCompletedDate && (
                            <div>
                                <Text
                                    variant="smallPlus"
                                    style={{ fontWeight: 600 }}
                                >
                                    Report Date:{' '}
                                </Text>
                                <Text>
                                    {dayjs(report.reportDate).format(
                                        'DD MMM YYYY'
                                    )}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>

                <div
                    style={{
                        textAlign: 'right',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        alignItems: 'end',
                    }}
                >
                    {!report?.utcCompletedDate && (
                        <span
                            style={{
                                fontSize: '1em',
                                fontWeight: 'bold',
                                color: currentTheme.semanticColors
                                    .severeWarningIcon,
                                textTransform: 'uppercase',
                            }}
                        >
                            Draft
                        </span>
                    )}

                    {!report?.utcCompletedDate && report?.utcUpdated && (
                        <Text variant="small">
                            Last saved:{' '}
                            {lastSavedDate
                                ?.tz(dayjs.tz.guess())
                                .format('DD MMM YYYY HH:mm (z)')}
                        </Text>
                    )}

                    {report?.utcCompletedDate && (
                        <Text variant="small">
                            This report was published on{' '}
                            {dayjs
                                .utc(report?.utcCompletedDate)
                                .tz(dayjs.tz.guess())
                                .format('DD MMM YYYY HH:mm (z)')}
                        </Text>
                    )}

                    {report?.utcDataDate &&
                        report?.utcCompletedDate !== report?.utcDataDate && (
                            <Text variant="small">
                                Measure of success and task values are as of{' '}
                                {dayjs
                                    .utc(report?.utcDataDate)
                                    .tz(dayjs.tz.guess())
                                    .format('DD MMM YYYY HH:mm (z)')}
                            </Text>
                        )}

                    {!report?.utcCompletedDate &&
                        report?.utcDataDate &&
                        !isReadOnly &&
                        currentTenantId &&
                        report.mission?.id && (
                            <div>
                                <StatusReportDataChangedIndicator
                                    tenantId={currentTenantId}
                                    missionId={report.mission?.id}
                                    utcDataDate={report.utcDataDate}
                                    onRefreshDataButtonClick={
                                        handleRefreshDataButtonClick
                                    }
                                />
                            </div>
                        )}
                </div>
            </div>

            {report.sections
                .slice()
                .sort(sorters.sequenceSorter)
                .filter(
                    (s) =>
                        s.templateReportViewId === selectedTemplateViewId ||
                        (!s.templateReportViewId && !selectedTemplateViewId)
                )
                .map((section, sectionIndex) => {
                    return (
                        <div key={section.id} className="break-after">
                            <TemplateReportSection
                                section={section}
                                report={report}
                                sectionIndex={sectionIndex}
                                isReadOnly={isReadOnly}
                                loading={loading}
                                onHandleMeasureNavigate={handleMeasureNavigate}
                                onHandleTaskNavigate={handleTaskNavigate}
                                onTitleUpdated={handleTitleUpdated}
                                fyStartDate={fyStartDate}
                                fyEndDate={fyEndDate}
                                onSectionTitleChanged={
                                    handleSectionTitleChanged
                                }
                                onAddElementClick={showAddElementPanel}
                                onChangeSectionLayoutClick={
                                    showSectionLayoutPanel
                                }
                                onElementUpdateError={handleElementUpdateError}
                                reportButtons={buttons}
                            />
                        </div>
                    );
                })}

            <TemplateReportDeleteModal
                isOpen={isDeleteModalOpen}
                onDismiss={() => setIsDeleteModalOpen(false)}
                onDeleted={() => setIsDeleteModalOpen(false)}
                templateReportId={templateReportId}
            />

            {!!report && !!mission && (
                <StatusReportPublishDialog
                    hidden={hideSubmitDialog}
                    onDismiss={handleSubmitDialogDismiss}
                    onConfirm={handleConfirmSubmitClick}
                    disabled={isUpdating || isUpdatingSection || loading}
                    teamLeader={teamLeader}
                    mission={mission}
                    reportDate={report.reportDate}
                    title={report.title}
                />
            )}

            <StatusReportUnPublishDialog
                hidden={hideUnsubmitDialog}
                onDismiss={handleUnsubmitDialogDismiss}
                onConfirm={handleConfirmUnsubmitClick}
                disabled={isUpdating || isUpdatingSection || loading}
            />

            <TemplateReportAddPanel
                isOpen={!!addPanelElement}
                sectionId={addPanelElement?.sectionId || null}
                onDismiss={closeAddElementPanel}
                elementType={addPanelElement?.elementType}
                onElementsAdded={handleElementsAdded}
                isReadOnly={isReadOnly}
                missionId={mission?.id}
            />

            <TemplateReportEditLayoutPanel
                isOpen={!!editSectionLayoutPanel}
                onDismiss={closeEditSectionLayoutPanel}
                layoutDirection={
                    editSectionLayoutPanel?.layoutDirection || null
                }
                onLayoutChanged={handleLayoutChanged}
                isReadOnly={isReadOnly}
            />

            <TemplateReportChangePeriodDialog
                fyStartDate={fyStartDate}
                fyEndDate={fyEndDate}
                hidden={hidePeriodChangeDialog}
                onPeriodSaveClick={handlePeriodSaveClick}
                onDismiss={() => setHidePeriodChangeDialog(true)}
                reportPeriodType={report.reportPeriodType}
                reportPeriod={report.reportPeriod}
            />

            <EditPanels
                activePanel={activePanel}
                missionId={report?.mission?.id}
                measureId={activeMeasureId}
                taskId={activeTaskId}
                teamId={mission?.team?.id}
                onActivePanelChanged={handleActivePanelChanged}
                onActiveTaskChanged={onActiveTaskChanged}
            />
        </div>
    );
}

function TemplateReportSection(props: {
    reportButtons: AreaContainerButton[];
    loading: boolean;
    sectionIndex: number;
    isReadOnly: boolean;
    fyStartDate: string | null | undefined;
    fyEndDate: string | null | undefined;
    report: ExtractQueryArrayType<
        GetTemplateReportQuery,
        ['reports', 'templateReport']
    >;
    section: ExtractQueryArrayType<
        GetTemplateReportQuery,
        ['reports', 'templateReport', 'sections']
    >;
    onTitleUpdated: (title?: string | null) => void;
    onHandleMeasureNavigate: (measureId: string) => void;
    onHandleTaskNavigate: (taskId: string) => void;
    onSectionTitleChanged: (
        sectionId: string | null | undefined,
        newTitle: string | null | undefined
    ) => Promise<void>;
    onElementUpdateError: (error: Error | null | undefined) => void;
    onChangeSectionLayoutClick: (
        sectionId: string,
        layoutDirection: string
    ) => void;
    onAddElementClick: (
        sectionId: string,
        elementType: TemplateReportElementTypes
    ) => void;
}): JSX.Element {
    const {
        section,
        report,
        sectionIndex,
        isReadOnly,
        loading,
        fyStartDate,
        fyEndDate,
        reportButtons,
    } = props;

    const { width } = useViewport();

    const maxSequence =
        Math.max(
            0,
            ...section.elements.map((e) => e.sequence),
            ...(section.groups?.map((g) => g.sequence) || [])
        ) + 1;

    const allowNavigation =
        !loading && !!report && !report.utcCompletedDate && !report.utcDataDate;

    const sectionButtons: (IButtonProps & {
        key: string;
    })[] = [];

    if (!isReadOnly && section.availableElementTypes?.length) {
        section.availableElementTypes?.forEach((et) => {
            sectionButtons.push({
                key: et,
                text:
                    et === TemplateReportElementTypes.CustomTable
                        ? 'Add Table'
                        : et,
                iconProps: {
                    iconName: 'Add',
                },
                onClick: () => {
                    props.onAddElementClick(section.id || '', et);
                },
            });
        });

        const objectCount = section.elements.length + section.groups.length;

        if (objectCount > 1) {
            sectionButtons.push({
                key: 'Layout',
                text: 'Change Layout',
                onClick: () =>
                    props.onChangeSectionLayoutClick(
                        section.id || '',
                        section.layoutDirection || 'row'
                    ),
                iconProps: {
                    styles: {
                        root: {
                            transform:
                                section.layoutDirection === 'column'
                                    ? 'rotate(90deg)'
                                    : undefined,
                        },
                    },
                    iconName:
                        objectCount >= 4
                            ? 'QuadColumn'
                            : objectCount == 3
                              ? 'TripleColumn'
                              : 'DoubleColumn',
                },
            });
        }
    }

    const classNames = mergeStyleSets({
        sectionContainer: {
            display: 'flex',
            alignItems: 'stretch',
            padding: 8,
            gap: 16,
            // flex direction set dynamically inline
            '@media print': {
                alignItems: 'flex-start',
                alignSelf: 'flex-start',
            },
        },
        groupContainer: {
            display: 'flex',
            flexBasis: 0,
            gap: 16,
            alignItems: 'stretch',
            minWidth: 0,
            alignSelf: 'flex-start',
            // flex direction set dynamically inline
            '@media print': {
                alignItems: 'stretch',
                alignSelf: 'flex-start',
            },
        },
    });

    const findSourceElement = (element: {
        sourceTemplateReportSectionElementId: string | null;
    }): TemplateReportElementType | null => {
        return element.sourceTemplateReportSectionElementId
            ? report.sections
                  .flatMap((s) => s.elements)
                  ?.find(
                      (e) =>
                          e.id === element.sourceTemplateReportSectionElementId
                  ) || null
            : null;
    };

    return (
        <AreaContainer
            title={
                sectionIndex === 0 && report.title ? (
                    <StatusReportTitle
                        title={report?.title}
                        isReadOnly={isReadOnly}
                        onTitleUpdated={props.onTitleUpdated}
                    />
                ) : undefined
            }
            subTitle={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'end',
                    }}
                >
                    {!!section.title && (
                        <Text variant="mediumPlus">
                            <EditibleText
                                isRequired
                                isReadOnly={isReadOnly}
                                dialogTitle={'Edit Title'}
                                text={section.title || ''}
                                onUpdateClick={(newTitle: string) =>
                                    props.onSectionTitleChanged(
                                        section.id,
                                        newTitle
                                    )
                                }
                            >
                                {section.title}
                            </EditibleText>
                        </Text>
                    )}

                    <div>
                        {sectionButtons.map((sb) => (
                            <ActionButton {...sb} key={sb.key} />
                        ))}
                    </div>
                </div>
            }
            isCollapsable={false}
            commandBarButtons={
                sectionIndex === 0 ? [...reportButtons] : undefined
            }
        >
            <div
                className={classNames.sectionContainer}
                style={{
                    flexDirection:
                        width > 768
                            ? (section.layoutDirection as
                                  | 'column'
                                  | 'column-reverse'
                                  | 'row'
                                  | 'row-reverse')
                            : 'column',
                }}
            >
                {[...Array(maxSequence)].map((_obj, sequence) => {
                    return (
                        <React.Fragment key={sequence}>
                            {section.elements
                                .slice()
                                .sort(sorters.sequenceSorter)
                                .filter(
                                    (e) => e.sequence == sequence && !e.groupId
                                )
                                .map((element, elementIndex) => {
                                    return (
                                        <TemplateReportElement
                                            key={elementIndex}
                                            missionId={report.mission?.id || ''}
                                            templateReportId={report.id || ''}
                                            fyStartDate={fyStartDate}
                                            fyEndDate={fyEndDate}
                                            element={element}
                                            sourceElement={findSourceElement(
                                                element
                                            )}
                                            isReadOnly={isReadOnly}
                                            onElementUpdateError={
                                                props.onElementUpdateError
                                            }
                                            utcCompletedDate={
                                                report.utcCompletedDate
                                            }
                                            utcDataDate={report.utcDataDate}
                                            onMeasureNavigate={
                                                allowNavigation
                                                    ? props.onHandleMeasureNavigate
                                                    : null
                                            }
                                            onTaskNavigate={
                                                allowNavigation
                                                    ? props.onHandleTaskNavigate
                                                    : null
                                            }
                                            reportPeriod={report.reportPeriod}
                                            reportPeriodType={
                                                report.reportPeriodType
                                            }
                                        />
                                    );
                                })}

                            {!!section.groups &&
                                section.groups
                                    .slice()
                                    .sort(sorters.sequenceSorter)
                                    .filter((e) => e.sequence == sequence)
                                    .map((group) => (
                                        <div
                                            className={
                                                classNames.groupContainer
                                            }
                                            style={{
                                                flexGrow:
                                                    group.layoutGrow ||
                                                    undefined,
                                                flexDirection:
                                                    group.layoutDirection as
                                                        | 'column'
                                                        | 'column-reverse'
                                                        | 'row'
                                                        | 'row-reverse',
                                            }}
                                            key={group.sequence}
                                        >
                                            {section.elements
                                                .slice()
                                                .sort(sorters.sequenceSorter)
                                                .filter(
                                                    (e) => e.groupId == group.id
                                                )
                                                .map(
                                                    (element, elementIndex) => {
                                                        return (
                                                            <TemplateReportElement
                                                                key={
                                                                    elementIndex
                                                                }
                                                                missionId={
                                                                    report
                                                                        .mission
                                                                        ?.id ||
                                                                    ''
                                                                }
                                                                templateReportId={
                                                                    report.id ||
                                                                    ''
                                                                }
                                                                fyStartDate={
                                                                    fyStartDate
                                                                }
                                                                fyEndDate={
                                                                    fyEndDate
                                                                }
                                                                element={
                                                                    element
                                                                }
                                                                sourceElement={findSourceElement(
                                                                    element
                                                                )}
                                                                isReadOnly={
                                                                    isReadOnly
                                                                }
                                                                onElementUpdateError={
                                                                    props.onElementUpdateError
                                                                }
                                                                utcCompletedDate={
                                                                    report.utcCompletedDate
                                                                }
                                                                utcDataDate={
                                                                    report.utcDataDate
                                                                }
                                                                onMeasureNavigate={
                                                                    allowNavigation
                                                                        ? props.onHandleMeasureNavigate
                                                                        : null
                                                                }
                                                                onTaskNavigate={
                                                                    allowNavigation
                                                                        ? props.onHandleTaskNavigate
                                                                        : null
                                                                }
                                                                reportPeriod={
                                                                    report.reportPeriod
                                                                }
                                                                reportPeriodType={
                                                                    report.reportPeriodType
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                        </div>
                                    ))}
                        </React.Fragment>
                    );
                })}
            </div>
        </AreaContainer>
    );
}

function TemplateReportDeleteModal(props: {
    templateReportId: string | null | undefined;
    isOpen: boolean;
    onDeleted: () => void;
    onDismiss: () => void;
}): JSX.Element {
    const { currentTenantId } = useStateContext();

    const params = useParams();
    const navigate = useNavigate();

    const [deleteTemplateReport, { loading: isDeleting, error: deleteError }] =
        useDeleteTemplateReportMutation();

    const deleteAction = async () => {
        if (currentTenantId && props.templateReportId) {
            await deleteTemplateReport({
                variables: {
                    tenantId: currentTenantId,
                    templateReportId: props.templateReportId,
                    restore: false,
                },
            });
        }

        props.onDeleted();

        const href = generatePath(paths.statusReports, params);
        navigate(href);
    };

    return (
        <DeleteModal
            activeViewName="TemplateReportDelete"
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            isDeleting={isDeleting}
            deleteAction={deleteAction}
            error={deleteError}
            message="Are you sure you want to delete this report?"
        />
    );
}

export type TemplateReportGroupType = {
    id: string | null;
    sectionId: string | null;
    sequence: number;
    layoutGrow: string | null;
    layoutDirection: string | null;
};

export type TemplateReportElementType = {
    id: string | null;
    sectionId: string | null;
    groupId: string | null;
    sourceTemplateReportSectionElementId: string | null;
    attachmentId: string | null;
    title: string | null;
    sequence: number;
    type: TemplateReportElementTypes;
    layoutGrow: string | null;
    textContent: string | null;
    utcUpdated: string | null;
    version: string | null;
    measureLinks: {
        id: string | null;
        sequence: number;
        measureId: string;
    }[];
    taskLinks: {
        id: string | null;
        sequence: number;
        taskId: string;
    }[];
    columns: {
        id: string | null;
        columnName: string | null;
        sequence: number;
        cells: {
            id: string | null;
            rowIndex: number;
            content: string | null;
        }[];
        version: string | null;
    }[];
};
