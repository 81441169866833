import { Label } from '@fluentui/react';
import styles from './ApiHelp.module.css';

export function ApiHelpTeams(): JSX.Element {
    return (
        <div>
            <h1>Teams</h1>
            A company may have one or more Teams
            <br />
            <p>
                <Label>OData Query Parameters</Label>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                <code className={styles.method}>GET</code>
                                <code className={styles.code}>/Team</code>
                            </td>
                            <td>
                                Get a List of Teams
                                <br />
                                <br />
                                Expandable properties: <br />
                                <code className={styles.code}>
                                    ?$expand=Division
                                </code>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <code className={styles.method}>GET</code>
                                <code className={styles.code}>
                                    /Team(&#123;id&#125;)
                                </code>
                            </td>
                            <td>
                                Get single Team
                                <p>
                                    <i>
                                        (Replace &#123;id&#125; with the guid
                                        identifying the individual record).
                                    </i>
                                </p>
                                <p>
                                    This can also be done with a filter query:
                                    <br />
                                    <i>
                                        <code className={styles.code}>
                                            $filter=Id eq &#123;id&#125;
                                        </code>
                                    </i>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </p>
        </div>
    );
}
