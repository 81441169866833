import * as React from 'react';

import {
    useGetApiKeysQuery,
    UserApiKeyQl,
    useDeleteApiKeyMutation,
    GetApiKeysQuery,
} from '../../../data/types';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';
import { useStateContext } from '../../../services/contextProvider';
import {
    ShimmeredDetailsList,
    IconButton,
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    Stack,
    Persona,
    PersonaSize,
    Selection,
    DefaultButton,
} from '@fluentui/react';
import { useSorter } from '../../../hooks/useSorter';
import { photoService } from '../../../services/photo.service';
import dayjs from 'dayjs';
import DeleteModal from '../../../components/shared/DeleteModal';
import { useThemes } from '../../../hooks/useThemes';
import AddApiKeyPanel from './AddApiKeyPanel';

type Props = {};

type UserApiKeyQuery = ExtractQueryArrayType<GetApiKeysQuery, ['apiKeys']>;

export function ApiKeysPivot(props: Props): JSX.Element {
    const { currentTenantId } = useStateContext();
    const { currentTheme } = useThemes();

    const [selectedKey, setSelectedKey] = React.useState<UserApiKeyQl>();
    const [isAdding, setIsAdding] = React.useState(false);
    const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [deleteErrorResult, setDeleteErrorResult] = React.useState<{
        message: string | null;
    } | null>();

    const { data: apiKeysData, loading: apiKeysLoading } = useGetApiKeysQuery({
        skip: !currentTenantId,
    });

    const [deleteKey, { error: deleteError }] = useDeleteApiKeyMutation({
        variables: {
            tenantId: currentTenantId || '',
            id: selectedKey?.id || '',
        },
        refetchQueries: ['GetApiKeys'],
    });

    const deleteAction = async (): Promise<void> => {
        setIsDeleting(true);

        const result = await deleteKey();
        if (!result.data?.userApiKeyDelete?.success) {
            setDeleteErrorResult(result.data?.userApiKeyDelete || null);
        }
        setIsConfirmingDelete(false);
        setIsDeleting(false);
    };

    const { sortColumn, sortIsDesc, sortedItems, setSortColumnName } =
        useSorter(apiKeysData?.apiKeys, 'apiKeyLabel', true, false);

    const _selection = new Selection();

    const columns: IColumn[] = [
        {
            key: 'username',
            name: 'User',
            fieldName: 'username',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            isSorted: sortColumn === 'username',
            isSortedDescending: sortColumn === 'username' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('username', true),
            onRender: function renderDisplayName(item: {
                user: {
                    id: string | null;
                    displayName: string | null;
                    emailAddress: string | null;
                };
            }): JSX.Element {
                if (item.user?.id) {
                    return (
                        <Persona
                            text={
                                item.user?.displayName ||
                                item.user?.emailAddress ||
                                ''
                            }
                            imageUrl={photoService.getImageUrl(item.user?.id)}
                            size={PersonaSize.size24}
                        />
                    );
                } else {
                    return <span />;
                }
            },
        },
        {
            key: 'apiKeyLabel',
            name: 'Key Label',
            fieldName: 'apiKeyLabel',
            minWidth: 100,
            maxWidth: 180,
            isResizable: true,
            isSorted: sortColumn === 'apiKeyLabel',
            isSortedDescending: sortColumn === 'apiKeyLabel' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('apiKeyLabel', false),
        },
        {
            key: 'utcIssued',
            name: 'Issued',
            fieldName: 'utcIssued',
            minWidth: 100,
            maxWidth: 180,
            isResizable: true,
            isSorted: sortColumn === 'utcIssued',
            isSortedDescending: sortColumn === 'utcIssued' && sortIsDesc,
            onColumnClick: (): void => setSortColumnName('utcIssued', false),
            onRender: (value): string =>
                dayjs
                    .utc(value.utcBatchDate)
                    .tz(dayjs.tz.guess())
                    .format('DD MMM YYYY - HH:mm (z)'),
        },
        {
            key: 'actions',
            name: '',
            minWidth: 64,
            isIconOnly: true,
            isPadded: false,
            className: 'iconCell',
            onRender: function renderActions(item: UserApiKeyQl): JSX.Element {
                return (
                    <Stack horizontal>
                        <IconButton
                            iconProps={{
                                styles: {
                                    root: {
                                        color: currentTheme.semanticColors
                                            .errorIcon,
                                    },
                                },
                                iconName: 'Delete',
                            }}
                            onClick={async () => {
                                setSelectedKey(item);
                                setIsConfirmingDelete(true);
                                setIsDeleting(false);
                                setDeleteErrorResult(null);
                            }}
                        />
                    </Stack>
                );
            },
        },
    ];

    return (
        <Stack>
            <h1 style={{ marginBottom: 0 }}>API Keys</h1>
            <ShimmeredDetailsList
                setKey="items"
                items={sortedItems || []}
                columns={columns}
                selectionMode={SelectionMode.none}
                selection={_selection}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={apiKeysLoading}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={{
                    renderedWindowsAhead: 0,
                    renderedWindowsBehind: 0,
                }}
            />

            <AddApiKeyPanel
                showPanel={isAdding}
                onCancel={() => {
                    setIsAdding(false);
                }}
            />

            <DefaultButton
                text="Add Key"
                iconProps={{ iconName: 'Add' }}
                onClick={() => {
                    setIsAdding(true);
                }}
                style={{ width: '10em', marginTop: 12 }}
            />

            <DeleteModal
                activeViewName="ApiKeys"
                isOpen={isConfirmingDelete}
                onDismiss={(): void => setIsConfirmingDelete(false)}
                message={'Are you sure you want to delete this Key?'}
                deleteAction={deleteAction}
                isDeleting={isDeleting}
                error={deleteError || deleteErrorResult}
            />
        </Stack>
    );
}
