import { Label } from '@fluentui/react';
import styles from './ApiHelp.module.css';
import { useStateContext } from '../../../../services/contextProvider';

export function ApiHelpFinancialYears(): JSX.Element {
    return (
        <div>
            <h1>Financial Years</h1>
            Information are grouped into Financial Years.
            <br />
            This allows data to be structured differently each year, but still
            keep a record to look back/forwards.
            <p>
                <Label>OData Query Parameters</Label>
                <table className={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                <code className={styles.method}>GET</code>
                                <code className={styles.code}>
                                    /FinancialYear
                                </code>
                            </td>
                            <td>
                                Get a List of Financial Years
                                <br />
                                Expandable property:{' '}
                                <code className={styles.code}>
                                    ?$expand=Divisions
                                </code>
                                .
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <code className={styles.method}>GET</code>
                                <code className={styles.code}>
                                    /FinancialYear(&#123;id&#125;)
                                </code>
                            </td>
                            <td>
                                Get single Financial Year
                                <p>
                                    <i>
                                        (Replace &#123;id&#125; with the guid
                                        identifying the individual record).
                                    </i>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </p>
        </div>
    );
}
