import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    GetMeasurePeriodDataQuery,
    GetMeasuresForStatusReportQuery,
    ReportPeriodTypes,
    useGetMeasurePeriodDataLazyQuery,
    useGetMeasuresForStatusReportLazyQuery,
} from '../../../data/types';
import { useStateContext } from '../../../services/contextProvider';
import { ExtractQueryArrayType } from '../../../data/extendedTypes';

type PeriodData = ExtractQueryArrayType<
    GetMeasurePeriodDataQuery,
    ['periodData', 'measurePeriodData']
>;

export function useTemplateReportMeasures(props: {
    utcDataDate: string | null;
    utcCompletedDate: string | null;
    missionId: string | null;
    reportPeriodType: ReportPeriodTypes | null;
    reportPeriod: number | null;
}): {
    selectedMeasureIds: string[];
    setSelectedMeasureIds: Dispatch<SetStateAction<string[]>>;
    isLoading: boolean;
    measures: GetMeasuresForStatusReportQuery['measures'];
    forDateTime: string | null;
    periodData: PeriodData[];
    hasNextTargets: boolean;
} {
    const [selectedMeasureIds, setSelectedMeasureIds] = useState<string[]>([]);
    const [periodData, setPeriodData] = useState<PeriodData[]>([]);
    const [selectedMeasures, setSelectedMeasures] = useState<
        GetMeasuresForStatusReportQuery['measures']
    >([]);

    const { currentTenantId } = useStateContext();

    const forDateTime = useMemo(
        () => props.utcDataDate || props.utcCompletedDate,
        [props.utcDataDate, props.utcCompletedDate]
    );

    const [loadMeasures, { data: measureData, loading: measuresLoading }] =
        useGetMeasuresForStatusReportLazyQuery();

    const [loadPeriodData, { loading: periodDataLoading }] =
        useGetMeasurePeriodDataLazyQuery({
            fetchPolicy: 'no-cache',
        });

    useEffect(() => {
        if (currentTenantId && props.missionId) {
            loadMeasures({
                // We don't want the historic results polluting the cache
                fetchPolicy: !forDateTime ? 'cache-and-network' : 'no-cache',
                variables: {
                    tenantId: currentTenantId,
                    missionId: props.missionId,
                    forDateTime: forDateTime,
                    includeDeleted: false,
                },
            });
        }
    }, [forDateTime, props.missionId, currentTenantId, loadMeasures]);

    useEffect(() => {
        const measures: GetMeasuresForStatusReportQuery['measures'] = [];
        if (!measuresLoading && measureData) {
            for (const id of selectedMeasureIds) {
                const measure = measureData?.measures.find((m) => m.id === id);
                if (measure) {
                    measures.push(measure);
                }
            }
        }
        setSelectedMeasures(measures);
    }, [measureData, measuresLoading, selectedMeasureIds]);

    const loadMeasurePeriodData = useCallback(async () => {
        const functions = selectedMeasures.map((measure) =>
            measure
                ? loadPeriodData({
                      variables: {
                          tenantId: currentTenantId || '',
                          forDateTime: forDateTime,
                          measureId:
                              (measure.isLinked
                                  ? measure.linkedFromMeasureId
                                  : measure.id) || '',
                          reportPeriod: props.reportPeriod,
                          reportPeriodType:
                              props.reportPeriodType || ReportPeriodTypes.None,
                      },
                  })
                : undefined
        );

        const results = await Promise.all(functions);

        const updatedPeriodData: PeriodData[] = [];

        for (const r of results) {
            if (r?.data?.periodData?.measurePeriodData) {
                updatedPeriodData.push(r.data?.periodData?.measurePeriodData);
            }
        }

        setPeriodData(updatedPeriodData);
    }, [
        selectedMeasures,
        loadPeriodData,
        currentTenantId,
        forDateTime,
        props.reportPeriod,
        props.reportPeriodType,
    ]);

    useEffect(() => {
        loadMeasurePeriodData();
    }, [loadMeasurePeriodData]);

    // If there are any targets and the next period is actually another period.
    const hasNextTargets =
        props.reportPeriodType !== ReportPeriodTypes.Ytd &&
        periodData?.some((m) => m.nextTargetFormatted !== null);

    return {
        selectedMeasureIds,
        setSelectedMeasureIds,
        isLoading: measuresLoading || periodDataLoading,
        measures: selectedMeasures,
        forDateTime: forDateTime,
        periodData: periodData,
        hasNextTargets: hasNextTargets,
    };
}
